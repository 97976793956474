import aboutHome from "assets/images/about_home.jpg";
import parse from "html-react-parser";
import { IoArrowForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import style from "./AboutUsSection.module.css";
export default function AboutUsSection() {
  return (
    <div className={style.parent}>
      <div className={style.container}>
        <div className={style.aboutImageContainer}>
          <img src={aboutHome} alt={"About"} className={style.aboutImage} />
        </div>
        <div className={style.content}>
          <div className={style.titleContainer}>
            <div className={style.title}>About</div>
            <div className={style.title2}>Poona Flour & Foods</div>
          </div>
          <div className={style.description}>
            {parse(`Established in 1988, Poona Flour and Foods and Poona Roller Flour Mills Ltd. are leading
manufacturers of quality FMCG products with over 25 years of experience in the industry.
Our products include <strong> High-quality Wheat flour (Atta), Refined Wheat flour (Maida),
Semolina (Rawa/Sooji), Tandoori atta, Wheat bran, and recently introduced Besan and
Pulses </strong>. We have two plants operating from Hadapsar in Pune and Uruli Kanchan. Led by
an experienced team, we prioritize customer satisfaction with our motto.`)}
            <div className={style.descriptionMoto}>
              "Quality, a way of life"
            </div>
          </div>
          <Link to="/about" className={style.aboutUsButton}>
            Know More
            <div className={style.aboutUsButtonIcon}>
              <IoArrowForward />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
