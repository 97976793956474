import "App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AboutScreen from "screens/about/about_screen";
import CareersScreen from "screens/careers/careers_screen";
import ContactScreen from "screens/contact/contact_screen";
import HomeScreen from "screens/home/home_screen";
import ProductScreen from "screens/products/product_screen";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeScreen />,
    },
    {
      path: "contact",
      element: <ContactScreen />,
    },
    {
      path: "products",
      element: <ProductScreen />,
    },
    {
      path: "about",
      element: <AboutScreen />,
    },
    {
      path: "careers",
      element: <CareersScreen />,
    },
  ]);
  return (
    <div className="app-root">
      <ToastContainer />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
