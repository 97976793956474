import { db } from "firebase.js";
import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import style from "./ProductSection.module.css";

export default function ProductSection() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilter, setSeletedFilter] = useState("000");

  const onFilterSelect = (filterValue) => {
    setSeletedFilter(filterValue);
    if (filterValue === "000") {
      setFilteredProducts(products);
    } else {
      const data = products
        .filter((e) => e.data.category === filterValue)
        .sort((a, b) => {
          return a.data.orderId - b.data.orderId;
        });
      const b2c = data.filter((d) => d.data.productType === "B2C");
      const b2d = data.filter((d) => d.data.productType === "B2B");
      const arrangedProducts = [...b2c, ...b2d];
      setFilteredProducts(arrangedProducts);
    }
  };

  useEffect(() => {
    const q = query(collection(db, "products"));
    onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
        .sort((a, b) => {
          if (a.data.category === b.data.category) {
            return a.data.orderId - b.data.orderId;
          }
          return a.data.category - b.data.category;
        });
      const b2c = data.filter((d) => d.data.productType === "B2C");
      const b2d = data.filter((d) => d.data.productType === "B2B");
      const arrangedProducts = [...b2c, ...b2d];
      setFilteredProducts(arrangedProducts);

      setProducts(arrangedProducts);
    });
    const filterQuery = query(collection(db, "productCategories"));
    onSnapshot(filterQuery, (querySnapshot) => {
      setFilters([
        {
          id: "000",
          data: {
            name: "All products",
            value: "000",
          },
        },
        ...querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
          .sort((a, b) => a.data.order.localeCompare(b.data.order)),
      ]);
    });
  }, []);
  return (
    <div className={style.parent}>
      <div className={style.container}>
        <div className={style.productGridTitle}>Our Products</div>
        <div className={style.productFilterCheckboxContainer}>
          {filters.map((e) => (
            <div
              key={e.id}
              style={{
                "--product-pill-background":
                  e.data.value === selectedFilter ? "#F3BC4C" : "#ffffff",
                "--product-pill-color":
                  e.data.value === selectedFilter ? "#FFFFFF" : "#353441",
                "--product-pill-border":
                  e.data.value === selectedFilter ? "#F3BC4C" : "#353441",
              }}
              className={style.filterChip}
              onClick={() => onFilterSelect(e.data.value)}
            >
              {e.data.name}
            </div>
          ))}
        </div>
        <div className={style.productGrid}>
          {filteredProducts.map((e) => (
            <ProductCard key={e.id} data={e.data} />
          ))}
        </div>
      </div>
    </div>
  );
}

function ProductCard({ data }) {
  return (
    <div className={style.productCardContainer}>
      <img
        className={style.productImage}
        src={data.imageLink}
        alt="productImage"
      />
      <div className={style.productContent}>
        <div className={style.productTitle}>{data.name}</div>
        <div className={style.productInfo}>{data.quantity.join(",    ")}</div>
      </div>
    </div>
  );
}
