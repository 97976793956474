import aboutImage from "assets/images/about.jpg";
import visionImage from "assets/images/vision.jpg";
import { ScrollRestoration } from "react-router-dom";
import FooterSection from "screens/home/widgets/FooterSection/footerSection.jsx";
import Navbar from "widgets/Navbar/Navbar";
import AboutSection from "./widgets/AboutSection/AboutSection.jsx";
import Moto from "./widgets/Motto/Moto.jsx";

export default function AboutScreen() {
  const aboutData = {
    title: "About Us",
    imageLink: aboutImage,
    description: `There are two establishments: Poona Flour Foods (PFF) and Poona Roller Flour Mills Ltd. (PRFM). Established in the year 1988, Poona Flour and Foods is an eminent firm in Hadapsar Industrial Estate, Pune, Maharashtra. Wheat is obtained in bulk quantity from all over India or through imports, depending on the market conditions and rates prevalent at that time. The firm has 2 plants: :

    1. <strong>Poona Flour and Foods Hadapsar, Pune Flour Mill:</strong> Wheat Flour (Atta), Refined Wheat Flour (Maida), Semolina (Rawa/Sooji), Tandoori Atta, Pulses, and Wheat Bran (Cattle Feed).
    
    2. <strong>Poona Roller Flour Mills Ltd. Uruli Kanchan, Pune: </strong> A Chakki Atta Plant and Gram Flour (Besan) was setup keeping in mind the future trend in the market and adding more product lines to the basket.
    
    We also offer customized packaging solutions to our clients as per their requirements. We use quality packaging material to pack our entire range so as to ensure the safety of our products. Owing to our close proximity to the city and our spacious warehousing facility, we cater to the small and bulk requirements of our clients within a specific time period. Our products are widely used in homes, restaurants, hotels, and various other food joints. HIRA is a well-known brand for its quality and is widely preferred by bakeries and confectionaries. As the plant is situated very close to the city, the wholesalers and distributors do not have to maintain high inventories and can give fresh products to their customers on a daily basis. The overhead costs like transportation and handling are also reduced to a large extent as they do not have to take cargo to their warehouses but can give deliveries of products directly from the plant which in turn gives our products an edge over other brands in pricing also.
    
    Our firm has flourished under the expert guidance of our partners, Jalamchand Kesharchand Parakh, and Swapnil Jalamchand Prakh. They have helped the firm flourish in this industry as a whole with their charismatic skills, vast knowledge, and experience. Mr. J.K. Parakh is a pioneer in the food processing industry, having a vast experience of more than 35 years in this field. A B.Sc. by education, he is specialized in the setting up of plants with the latest technology and constantly updates himself with the latest technical know-how to upgrade the quality of our plants. He also oversees the purchase of raw materials and the sale of finished products. Mr. S.J. Parakh oversees the banking, finance, and production activities of both units. He has introduced systems right from procuring the raw material coming in to the finished product going out. Thereby looking after the smooth functioning of the production, marketing, and distribution activities along with the day-to-day operations of the company. We have a dedicated and experienced team of technical, financial, and marketing experts whose combined and well-coordinated efforts always keep the customers’ requirements and satisfaction as their main priority.
    The company’s motto is <strong>“Quality, a way of life.”</strong> Our transparent and ethical practices, cost-effective prices, superior-quality products, timely delivery, and efficient workforce have enabled us to gain the trust of customers and garner a strong client base all across the country.`,
  };
  const visionData = {
    title: "Vision",
    imageLink: visionImage,
    description: `Delivering small packets straight to household customers, our vision is to revolutionize accessibility by empowering every corner of the country with quality and convenience. We want to ensure convenience and quality across the nation by bringing the wholesome goodness of our products to every household through innovation, dependability, and a dedication to excellence." Our goal as a food FMCG manufacturer is to use our small packages to directly serve consumers across the country, going beyond B2B. Our goal is to improve convenience and improve people's lives all around the nation by making our superior products affordable to everyone.`,
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <ScrollRestoration/>
      <Navbar />
      <Moto />
      <AboutSection
        title={aboutData.title}
        imageLink={aboutData.imageLink}
        data={aboutData.description}
      />
      <AboutSection
        isReverse={true}
        title={visionData.title}
        imageLink={visionData.imageLink}
        data={visionData.description}
      />
      <FooterSection />
    </div>
  );
}
