import style from "./mapWidget.module.css";
export default function MyMapComponent() {
  const renderMap = () => {
    return (
      <div className={style.parent}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15133.492610265892!2d73.9106613!3d18.5120321!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1bf914d7183%3A0xf2f93e4f984371fe!2sPoona%20Flour%20and%20Foods!5e0!3m2!1sen!2sin!4v1711185728804!5m2!1sen!2sin"
          width="100%"
          height="560"
          allowFullScreen=""
          loading="lazy"
          title="Map"
          style={{
            height: "560px",
            width: "100%",
            borderRadius: "24px",
            boxShadow: "0px 4px 16px #00000024",
            backgroundColor: "#ececec",
          }}
        ></iframe>
      </div>
    );
  };

  return renderMap();
}
