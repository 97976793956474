import windmill from "assets/animations/windmill.gif";
import style from "./CareersHeaderWidget.module.css";
export default function CareersHeaderWidget({hiring}) {
  return (
    <div className={style.parent}>
      <div className={style.container}>
        <img src={windmill} className={style.headerAnimation} alt="windmill" />
        <div className={style.headerTitle}>
          Join Our team <div className={style.headerBadge}>{hiring?'Not currently hiring':"We're  hiring" }</div>
        </div>
        <div className={style.headerDescription}>
        Focus on growth, be part of the team that brings quality flour to life. 
        </div>
      </div>
    </div>
  );
}
