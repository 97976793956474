import countour from "assets/images/countour.png";
import wheatSackImage from "assets/images/wheat_sack.png";
import { IoArrowForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import style from "./ctaSection.module.css";

export default function CTASection() {
  return (
    <div className={style.parent}>
      <div className={style.container}>
        <img className={style.countour} src={countour} alt="contour" />
        <img className={style.wheatSackImage} src={wheatSackImage} alt="sack" />
        <div className={style.content}>
          <div className={style.headerTitle}>From our mill to your kitchen</div>
          <div className={style.headerSubtitle}>
            Every delicious creation begins with the perfect flour
          </div>
          <Link to='/products' className={style.ctaButton}>
            View our products
            <div className={style.ctaButtonIcon}>
              <IoArrowForward />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
