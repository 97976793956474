import brownSack from "assets/images/brown_sack.png";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { BiSolidFactory } from "react-icons/bi";
import { GiFlour } from "react-icons/gi";
import { IoIosStar } from "react-icons/io";
import style from "./KeyFigures.module.css";

export default function KeyFigures() {
  const data = [
    { title: "35+", subTitle: "years in industry", icon: AiFillSafetyCertificate },
    { title: "4.2", subTitle: "Customer rating", icon: IoIosStar },
    { title: "2", subTitle: "Locations", icon: BiSolidFactory },
    { title: "20+", subTitle: "Products", icon: GiFlour },
  ];
  return (
    <div className={style.parent}>
      <div className={style.container}>
        <div className={style.imageContainer}>
          <img className={style.image} src={brownSack} alt={"sack"} />
        </div>
        <div className={style.figuresContainer}>
          {data.map((e) => (
            <Figure
              key={e.title}
              icon={e.icon}
              title={e.title}
              subtitle={e.subTitle}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function Figure({ icon, title, subtitle }) {
  const Icon = icon;
  return (
    <div className={style.figure}>
      <Icon className={style.figureIcon}/>
      <div className={style.figureTitle}>{title}</div>
      <div className={style.figureSubtitle}>{subtitle}</div>
    </div>
  );
}
