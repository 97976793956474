import heroVideo from "assets/hero.mp4";
import heroBackgroundImage from "assets/images/hero_background_test_2.svg";
import { IoArrowForward } from "react-icons/io5";
import { Link } from "react-router-dom";

import style from "./HeroSection.module.css";
export default function HeroSection() {
  return (
    <div className={style.parent}>
      <div className={style.heroBackground}>
        <img className={style.heroImage} src={heroBackgroundImage} alt="hero" />
        <div className={style.contentBackground}>
          <div className={style.contentDataContainer}>
            <div className={style.headerContainer}>
              <div className={style.heroHeader}>
                The <span style={{ color: "#558E68" }}>flour</span> you knead
              </div>
              <div className={style.heroDescription}>
                We mill the finest flours from sustainably sourced grains.
                Experience the difference quality ingredients can make
              </div>
              <Link to="/contact" className={style.heroButton}>
                Inquire Now
                <div className={style.heroButtonIcon}>
                  <IoArrowForward />
                </div>
              </Link>
            </div>
          </div>
          <div className={style.contentVideoContainer}>
            <video
              src={heroVideo}
              alt="hero"
              muted={true}
              autoPlay={true}
              loop={true}
              preload="auto"
              className={style.contentVideoBox}
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
}
