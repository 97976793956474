import logo from "assets/logo.png";
import { useState } from "react";
import { FaBars } from "react-icons/fa6";
import { Link } from "react-router-dom";
import style from "./Navbar.module.css";
import Sidebar from "./Sidebar/sidebar";

export default function Navbar() {
  const [showSideBar, setShowSideBar] = useState(false);

  const onClickShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  return (
    <>
      {showSideBar ? (
        <Sidebar onClose={() => onClickShowSideBar()} show={showSideBar} />
      ) : undefined}
      <div className={style.parent}>
        <div className={style.container}>
          <img className={style.logo} src={logo} alt="Logo" />
          <div className={style.navLinksContainer}>
            <NavbarLinks title={"Home"} path={"/"} />
            <NavbarLinks title={"Products"} path={"/products"} />
            <NavbarLinks title={"About Us"} path={"/about"} />
            <NavbarLinks title={"Careers"} path={"/careers"} />
          </div>
          <Link className={style.contact} to={"/contact"}>
            Contact Us
          </Link>
          <FaBars
            className={style.sidebarIcon}
            onClick={() => onClickShowSideBar()}
          />
        </div>
      </div>
    </>
  );
}

function NavbarLinks({ title, path }) {
  return (
    <Link className={style.navLinks} to={path}>
      {title}
    </Link>
  );
}
