import { ScrollRestoration } from "react-router-dom";
import Navbar from "widgets/Navbar/Navbar";
import AboutUsSection from "./widgets/AboutUsSection/AboutUseSection";
import CTASection from "./widgets/CTASection/ctaSection";
import FooterSection from "./widgets/FooterSection/footerSection";
import HeroSection from "./widgets/HeroSection/heroSection";
import KeyFigures from "./widgets/KeyFigures/KeyFigures";
import ProductSection from "./widgets/ProductSection/ProductSection";

export default function HomeScreen() {
  return (
    <div>
      <ScrollRestoration/>
      <Navbar />
      <HeroSection />
      <AboutUsSection />
      <ProductSection />
      <KeyFigures />
      <CTASection />
      <FooterSection />
    </div>
  );
}
