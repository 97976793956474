import footerProp from "assets/images/footer-prop.png";
import logo from "assets/logo.png";
import { remoteConfig } from "firebase.js";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { useEffect, useState } from "react";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { Link } from "react-router-dom";
import style from "./footerSection.module.css";

export default function FooterSection() {
  const [showCompany, setShowCompany] = useState({});
  const [extraLinks, setExtraLink] = useState([
    {
      name: "Careers",
      to: "/careers",
      isExternalLink: false,
    },
    {
      name: "Contact Us",
      to: "/contact",
      isExternalLink: false,
    },
    {
      name: "Privacy Policy",
      to: "htttp://www.google.com",
      isExternalLink: true,
    },
    {
      name: "Terms & Conditions",
      to: "htttp://www.google.com",
      isExternalLink: true,
    },
  ]);

  useEffect(() => {
    let greeting = showCompany;
    fetchAndActivate(remoteConfig)
      .then(() => {
        greeting = getValue(remoteConfig, "developedInfo");
        setShowCompany(JSON.parse(greeting._value));
        const privacyTermsLinks = getValue(remoteConfig, "privacy_terms");
        let privacyTermsLinksMap= JSON.parse(privacyTermsLinks._value);
        const extraLinksData = extraLinks;
        extraLinksData[2].to = privacyTermsLinksMap.privacy;
        extraLinksData[3].to = privacyTermsLinksMap.terms;
        setExtraLink(extraLinksData)
      })
      .catch((err) => {});
  });
  const webLinks = [
    {
      name: "Home",
      to: "/",
      isExternalLink: false,
    },
    {
      name: "About",
      to: "/about",
      isExternalLink: false,
    },
    {
      name: "Products",
      to: "/products",
      isExternalLink: false,
    },
  ];

  function showCompanyName(flag) {
    if ((flag.shouldShow ?? false) === true) {
      return (
        <a
          href={flag.link}
          target="_blank"
          rel="noreferrer"
          className={style.createdBy}
        >
          Created by: {flag.label}
        </a>
      );
    }
    return <></>;
  }
  return (
    <div>
      <div className={style.parent}>
        <div className={style.content}>
          <div className={style.social}>
            <img className={style.logoContainer} src={logo} alt="logo" />
            <a href="mailto:support@prfmpff.com" className={style.email}>
              support@prfmpff.com
            </a>
            <div className={style.socialContainer}>
              <a
                href={
                  "https://www.instagram.com/poonaflourandfoods?igsh=ZmVpa2N3MTltbDR4&utm_source=qr"
                }
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  className={style.socialIcon}
                  style={{ "--hover-color": "#C13584" }}
                />
              </a>
              <a
                href={
                  "https://www.linkedin.com/company/poona-flours-foods-pvt-ltd/"
                }
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin
                  className={style.socialIcon}
                  style={{ "--hover-color": "#0077b5" }}
                />
              </a>
            </div>
          </div>
          <div className={style.linksContainer}>
            <div className={style.links}>
              <div className={style.linksHeader}>Take a tour</div>
              <div className={style.linksTextContainer}>
                {webLinks.map((e) => (
                  <FooterNavLink data={e} key={e.name} />
                ))}
              </div>
            </div>
            <div className={style.links}>
              <div className={style.linksHeader}>Other Links</div>
              <div className={style.linksTextContainer}>
                {extraLinks.map((e) => (
                  <FooterNavLink data={e} key={e.name} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={style.image}>
          <img
            className={style.footerProp}
            src={footerProp}
            alt={"wheat prop"}
          />
        </div>
      </div>
      <div
        className={style.copyrightParent}
        style={{ "--grid-size": showCompany.shouldShow === true ? 2 : 1 }}
      >
        {showCompanyName(showCompany)}
        <div
          className={style.copyrightText}
          style={{
            "--alignment":
              showCompany.shouldShow === true ? "flex-end" : "center",
          }}
        >
          © {new Date().getFullYear()} Poona Flour and Foods, Inc. All Rights
          Reserved
        </div>
      </div>
    </div>
  );
}

function FooterNavLink({ data }) {
  return data.isExternalLink ? (
    <a
      href={data.to}
      className={style.linkText}
      target="_blank"
      rel="noreferrer"
    >
      {data.name}
    </a>
  ) : (
    <Link to={data.to} className={style.linkText}>
      {data.name}
    </Link>
  );
}
