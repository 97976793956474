import parse from "html-react-parser";
import style from "./AboutSection.module.css";
export default function AboutSection({ isReverse, title, data, imageLink }) {
  return (
    <div className={style.root}>
      <div
        className={style.imageParent}
        style={{
          "--float": isReverse === true ? "right" : "left",
          "--margin": isReverse === true ? "0 0 4px 40px" : "0 40px 4px 0",
        }}
      >
        <img src={imageLink} alt={title} className={style.image} />
      </div>
      <div className={style.textParent}>
        <div className={style.title}>{title}</div>
        <div className={style.description}>{parse(data)}</div>
      </div>
    </div>
  );
}
