import { db } from "firebase.js";
import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ScrollRestoration } from "react-router-dom";
import FooterSection from "screens/home/widgets/FooterSection/footerSection";
import Navbar from "widgets/Navbar/Navbar";
import CareersListing from "./CareersListing/CareersListing";
import CareersHeaderWidget from "./careersHeaderWidget/CareersHeaderWidget";

export default function CareersScreen() {
  const [careers, setCareers] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "careers"));
    onSnapshot(q, (querySnapshot) => {
      setCareers(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        })),
      );
    });
  }, []);
  return (
    <div>
      <ScrollRestoration/>
      <Navbar />
      <div style={{ height: "48px" }} />
      <CareersHeaderWidget hiring={ careers.length===0} />
      <div style={{ height: "64px" }} />
      <CareersListing careers={ careers} />
      <div style={{ height: "96px" }} />
      <FooterSection />
    </div>
  );
}
