import { db } from "firebase.js";
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import style from "./ProductSection.module.css";
export default function ProductSection() {
  const [topProducts, setTopProducts] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "products"),
      where("isTopProduct", "==", true),
      limit(4),
    );
    onSnapshot(q, (querySnapshot) => {
      setTopProducts(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        })),
      );
    });
  }, []);
  return (
    <div className={style.parent}>
      <div className={style.container}>
        <div className={style.productHeading}>Our Top Selling Products</div>
        <div className={style.productContainer}>
          <div className={style.productShelf}></div>
          <div className={style.productRow}>
            {topProducts.map((e) => (
              <Product data={e.data} key={e.id} />
            ))}
          </div>
          <Link to='/products' className={style.productKnowMore}>View All Products</Link>
        </div>
      </div>
    </div>
  );
}

function Product({ data }) {
  return (
    <div className={style.product}>
      <img
        className={style.productImage}
        src={data.imageLink}
        alt="productImage"
      />
      <div className={style.productTitle}>{data.name}</div>
      <div className={style.productSubtitle}>{data.quantity.join(", ")}</div>
    </div>
  );
}
