import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyCyHz5Fa-CXWXQmFftqDbz6qscU6xJ4380",
  authDomain: "prfmpff-e0d3d.firebaseapp.com",
  projectId: "prfmpff-e0d3d",
  storageBucket: "prfmpff-e0d3d.appspot.com",
  messagingSenderId: "1070673091864",
  appId: "1:1070673091864:web:85307ba252fe0f3be7526d",
  measurementId: "G-MPNC0W560E"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

export { db, remoteConfig };
