import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { IoArrowForward } from "react-icons/io5";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "../ContactFormSection.module.css";
import "./contact_form.css";

export default function ContactForm() {
  const formRef = useRef();

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      formRef.current.email.value === "" ||
      formRef.current.subject.value === "" ||
      formRef.current.message.value === ""
    ) {
      toast.error("Please Fill All The Fields", {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        theme: "colored",
      });
    } else {
      emailjs
        .sendForm(
          "service_avyeg9p",
          "template_v4urhl4",
          formRef.current,
          "pNDEwftex1ES_cBjD",
        )
        .then(
          (result) => {
            toast.success("Message Sent", {
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              theme: "colored",
            });
          },
          (error) => {
            toast.error("Message Sending Failed", {
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              theme: "colored",
            });
          },
        );
    }
    formRef.current.reset();
  };

  return (
    <form ref={formRef} className="contact-form-root" onSubmit={onSubmit}>
      <input
        className='form-field'
        placeholder="Email"
        type="text"
        name="email"
      />

      <input
        className='form-field'
        placeholder="Subject"
        type="text"
        name="subject"
      />
      <textarea
        className='form-field'
        placeholder="Message"
        type="text"
        name="message"
      />
      <div className={style.contactButton} onClick={onSubmit}>
        Contact Us
        <div className={style.contactButtonIcon}>
          <IoArrowForward />
        </div>
      </div>
    </form>
  );
}
