import logo from "assets/logo.png";
import { IoArrowForward, IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import style from "./sidebar.module.css";

export default function Sidebar({ onClose, show }) {
  const data = [
    { name: "Home", to: "/" },
    { name: "Products", to: "/products" },
    { name: "About", to: "/about" },
    { name: "Careers", to: "/careers" },
  ];
  const onClick = () => {
    onClose();
  };
  return (
    <aside className={style.sidebarRoot}>
      <div className={style.sidebarHeader}>
        <IoClose className={style.closeIcon} onClick={onClose} />
        <img src={logo} className={style.logo} alt={"logo"} />
      </div>
      <div className={style.linkContainer}>
        {data.map((e) => (
          <NavLink
            key={e}
            to={e.to}
            style={({ isActive }) => ({
              "--backgroundColor": isActive ? "#fdf4cf" : "transparent",
            })}
            className={style.link}
            onClick={() => onClick()}
          >
            {e.name}
          </NavLink>
        ))}
      </div>
      <NavLink to={"/contact"} className={style.contactButton}>
        Contact us <IoArrowForward className={style.contactButtonIcon} />
      </NavLink>
    </aside>
  );
}
