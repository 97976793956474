import parse from "html-react-parser";
import style from "./ContactDetailsSEction.module.css";

export default function ContactDetailSection() {
  return (
    <div className={style.parent}>
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.catgory}>
            <div className={style.catgoryTitle}>
              Poona Flour & Foods (Reg. Office)
            </div>
            <div className={style.catgoryInfoAddress}>
              {parse(
                `<p>103/104, B Takar Path, Swami Vivekanand Nagar,
                <br/> Hadapsar Industrial Estate,
                Hadapsar,
                <br/> Pune, Maharashtra 411013.</p>`,
              )}
            </div>
            <div className={style.catgoryInfoPhone}>
              {parse(`020-26820086 / 020-26823967`)}
            </div>
          </div>
          <div className={style.catgory}>
            <div className={style.catgoryTitle}>
              Poona Roller Flour Mills Ltd.
            </div>
            <div className={style.catgoryInfoAddress}>
              {parse(
                `<p>Parakh Nagar, Koregaon Mul,
                Urilikanchan <br/>Tal-Haveli, Pune,
                <br/>Maharashtra 412202.</p>`,
              )}
            </div>
            {/* <div className={style.catgoryInfoPhone}>
              {parse(`+91 20 26822235 / +91 20 26823967 / +91 9021671136`)}
            </div> */}
          </div>
          <div className={style.catgory}>
            <div className={style.catgoryTitle}>Email</div>
            <div className={style.catgoryInfoPhone}>
              support@prfmpff.com
            </div>
            <div className={style.catgoryInfoPhone}>
              sales@prfmpff.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
