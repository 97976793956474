import { ScrollRestoration } from "react-router-dom";
import FooterSection from "screens/home/widgets/FooterSection/footerSection";
import Navbar from "widgets/Navbar/Navbar";
import ContactDetailSection from "./widgets/ContactDetailsSection/ContactDetailsSection";
import ContactFormSection from "./widgets/ContactFormSection/ContactFormSection";
import MyMapComponent from "./widgets/mapWidget/mapWidget";

export default function ContactScreen() {
  return (
    <div>
      <ScrollRestoration />
      <Navbar />
      <MyMapComponent />
      <ContactDetailSection />
      <div style={{ height: "96px" }} />
      <ContactFormSection />
      <div style={{ height: "96px" }} />
      <FooterSection />
    </div>
  );
}
