import contactImage from "assets/images/contact.jpg";
import ContactForm from "./ContactForm/ContactForm";
import style from "./ContactFormSection.module.css";
export default function ContactFormSection() {
  return (
    <div className={style.parent}>
      <div className={style.container}>
        <div className={style.formContainer}>
          <div className={style.formHeader}>
            <div className={style.formTitle}>Contact Us</div>
            <div className={style.formDescription}>
            Email us with any questions or inquires. We will be happy to answer your questions.
            </div>
          </div>
          <ContactForm />
        </div>
        <div className={style.contactImageContainer}>
          <img
            className={style.contactImage}
            src={contactImage}
            alt={"Delivery"}
          />
        </div>
      </div>
    </div>
  );
}
